import React, { useState, useEffect } from "react"
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './write-review.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import StarRatingComponent from 'react-star-rating-component';
import Footer from '../components/footer'

import { useForm } from "react-hook-form";
import * as moment from 'moment';

/* https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f */
import queryString from 'query-string'

const SignUpReview = (props) => {
  
  const { handleSubmit, register, errors } = useForm();
  const [ isSubmitted, setIsSubmitted ] = useState(false);
  const [ submitError, setSubmitError ] = useState(false);
  const siteTitle = get(this, 'props.data.site.siteMetadata.title')  
  
  const onSubmit = values => 
  {
      console.log(JSON.stringify(values));
      
      // Post review to the API for submission.
      // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
      fetch(`https://www.confiable.club/.netlify/functions/submit-early-signup`,
        {
          method: 'POST',
          cache: 'no-cache',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values)
        })
        .then(response => {
            // check for 200-299 status codes
            if (!response.ok) {
              console.log('Network response was not ok');
              setSubmitError(true)
              return;
            }

            setIsSubmitted(true);

            return response.json();
        }) // parse JSON from request
        .then(resultData => 
        {
            console.log(JSON.stringify(resultData))
          //setStarsCount(resultData.stargazers_count)
        })
        .catch((error) => {

          setSubmitError(true)

          console.error('Unable to connect due to this error:', error);

          //this.setState({ submitErrorMessage : "Hubo un error. Porfavor intente de nuevo." });
        })
  }

 
  return (
      <Layout location={props.location}>
        <Helmet title={siteTitle} />          

          <div class="sub_header_in">
            <div class="container">
              <h1>Registro de Empresa</h1>
            </div>
          </div>

          <main>
		<div class="container margin_60">
			<div class="row justify-content-center">
        {/*<div class="col-xl-6 col-lg-6 col-md-8">        
         <div class="row">
				<div class="col-lg-12 col-md-12">
					<a class="box_feat" href="#0">
						<i class="pe-7s-medal"></i>
						<h3>+ 1000 Customers</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.</p>
					</a>
				</div>
				<div class="col-lg-6 col-md-12">
					<a class="box_feat" href="#0">
						<i class="pe-7s-help2"></i>
						<h3>H24 Support</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris. </p>
					</a>
				</div>
				<div class="col-lg-6 col-md-12">
					<a class="box_feat" href="#0">
						<i class="pe-7s-culture"></i>
						<h3>+ 5575 Locations</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.</p>
					</a>
				</div>
				<div class="col-lg-4 col-md-6">
					<a class="box_feat" href="#0">
						<i class="pe-7s-headphones"></i>
						<h3>Help Direct Line</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris. </p>
					</a>
				</div>
				<div class="col-lg-4 col-md-6">
					<a class="box_feat" href="#0">
						<i class="pe-7s-credit"></i>
						<h3>Secure Payments</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris.</p>
					</a>
				</div>
				<div class="col-lg-4 col-md-6">
					<a class="box_feat" href="#0">
						<i class="pe-7s-chat"></i>
						<h3>Support via Chat</h3>
						<p>Id mea congue dictas, nec et summo mazim impedit. Vim te audiam impetus interpretaris, cum no alii option, cu sit mazim libris. </p>
					</a>
				</div>
  </div>
        </div>*/}
        <div class="col-xl-6 col-lg-6 col-md-8">
        <div class="box_account">
        
        {!isSubmitted &&
          <form data-netlify="false" onSubmit={handleSubmit(onSubmit)} >					
					<div class="form_container">
            <h5 class="add_bottom_15">Contacto Principal</h5> 
            <div class="row no-gutters">
								<div class="col-6 pr-1">
									<div class="form-group">
                    <input name="firstName" type="text" class="form-control" placeholder="Nombre*"                      
                      ref={register({
                        required: "Required", 
                        min: 3, max: 100                        
                      })}
                      />
									</div>
                  {errors.firstName && <span class="error_message">Este campo es requerido.</span>}
								</div>
								<div class="col-6 pl-1">
									<div class="form-group">
                    <input name="lastName" type="text" class="form-control" placeholder="Apellido(s)*"
                       ref={register({
                        required: "Required",
                        min: 3, max: 100                      
                      })}
                      />
                    {errors.lastName && <span class="error_message">Este campo es requerido.</span>}
									</div>
								</div>
							</div>          
						<div class="form-group">
							<input  name="email" type="email" class="form-control" name="email" id="email" placeholder="Email*"
                 ref={register({
                  required: "Required",  
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Correo inválido."
                  }                      
                })}
                />
                 {errors.email && <span class="error_message">Este campo es requerido.</span>}
						</div>						
            <div class="form-group">
              <input  name="phoneNumber" type="text" class="form-control" placeholder="Telefono *"
                 ref={register({
                  required: "Required",
                  min: 3, max: 20,
                  pattern : {
                    value : /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i,
                    message : "Telefono invalido."
                  }                         
                })}
                />
                 {errors.phoneNumber && <span class="error_message">Este campo es requerido.</span>}
            </div>
						
            <h5 class="add_top_30 add_bottom_15">Empresa a Registrar</h5>					
						
            <div class="row no-gutters">								
								<div class="col-12">
									<div class="form-group">
										<input name="websiteUrl" type="text" class="form-control" placeholder="Sitio Web"
                       ref={register({
                        required: "Este campo es requerido.",  
                        pattern : {
                          value : /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6}|[\d\.]+)([\/:?=&#]{1}[\da-z\.-]+)*[\/\?]?$/i,
                          message : "URL invalido"
                        }                      
                      })}
                      />
                       {errors.websiteUrl && <span class="error_message">{errors.websiteUrl.message}</span>}
									</div>
								</div>
							</div>
              <div class="row no-gutters">								
								<div class="col-12">
									<div class="form-group">
										<input name="addressLine1"  type="text" class="form-control" placeholder="Direccion (Linea 1)*"
                      ref={register({
                        required: "Required",                        
                      })}
                      />
                       {errors.addressLine1 && <span class="error_message">Este campo es requerido.</span>}
									</div>
								</div>
							</div>
              <div class="row no-gutters">								
								<div class="col-12">
									<div class="form-group">
										<input name="addressLine2"  type="text" class="form-control" placeholder="Direccion (Linea 2)"
                         ref={register}
                        />                       
									</div>
								</div>
							</div>
							<div class="row no-gutters">
								<div class="col-6 pr-1">
									<div class="form-group">
										<input name="city"  type="text" class="form-control" placeholder="Ciudad*"
                       ref={register({
                        required: "Required",                        
                      })}
                      />
                       {errors.city && <span class="error_message">Este campo es requerido.</span>}
									</div>
								</div>
                <div class="col-6 pl-1">
									<div class="form-group">
										<input name="state"  type="text" class="form-control" placeholder="Estado / Provincia*"
                       ref={register({
                        required: "Required",                        
                      })}
                      />
                       {errors.state && <span class="error_message">Este campo es requerido.</span>}
									</div>
								</div>
								<div class="col-6 pr-1">
									<div class="form-group">
                    <input name="zipCode" type="text" class="form-control" placeholder="Codigo Postal*" 
                       ref={register({
                        required: "Required",                        
                      })}
                      />
                       {errors.zipCode && <span class="error_message">Este campo es requerido.</span>}
									</div>
								</div>
								<div class="col-6 pl-1">
									<div class="form-group">
                    <select class="form-control wide add_bottom_10" name="country"
                       ref={register({
                        required: "Este campo es requerido.",                        
                      })}>
                            <option value="">Pais*</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Argentina">Argentina</option>
                        </select>
                        {errors.country && <span class="error_message">{errors.country.message}</span>}
									</div>
								</div>
							</div>										
						
					
						<div class="form-group add_top_30 add_bottom_30">
							<label class="container_check">Aceptar <a href="/info/terminos-y-condiciones/" target="_blank">Términos y Condiciones</a>
								<input type="checkbox" name="acceptTerms" ref={register({
                          required: "Required",                        
                        })}
                        />
                         {errors.acceptTerms && <span class="error_message">Este campo es requerido.</span>}
								<span class="checkmark"></span>
							</label>
						</div>
						<div class="text-center">
              <input type="submit" value="Enviar Solicitud" class="btn_1 full-width"/>
            </div>
					</div>
          </form>}
          {isSubmitted && 
            <div id="confirm">
              <div class="icon icon--order-success svg add_bottom_15">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
                  <g fill="none" stroke="#8EC343" stroke-width="2">
                    <circle cx="36" cy="36" r="35" style={{ strokeDasharray: "240px, 240px", strokeDashoffset: "480px" }}></circle>
                    <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style={{ strokeDasharray: "50px, 50px", strokeDashoffset: "0px" }}></path>
                  </g>
                </svg>
              </div>
            <h2>!Solicitud Enviada!</h2>
            <p>Nos pondremos en contacto en las próximas 24 horas hábiles para terminar de procesar el registro de su empresa en Confiable.club.</p>
            </div>
          }

          {submitError && 
            <div class="error_message">Lo sentimos, hubo un problema al enviar su solicitud. Porfavor intente de nuevo en unos minutos.</div>
          }
				</div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-8">
				<div class="box_account">
          <h4 class="add_bottom_15 add_top_15">Registrarse es Gratuito</h4>
          <p>
            Forma parte de la comunidad de empresarios buscando ser una mejor empresa para sus clientes y proveedores. Haz que la voz de ellos
            sean la carta de presentación y te ayuden a crecer.
          </p>

          <div class="row">
				<div class="col-lg-12 col-md-12">
					<a class="box_feat" href="#">
						<i class="pe-7s-medal"></i>						
						<p>A partir de Agosto del 2020 hemos estado creciendo más de 50 empresas por semana.</p>
					</a>
				</div>				
        </div>
				
				<div class="row hidden_tablet add_top_30">
					<div class="col-md-12">
            <h5 class="add_bottom_15 add_top_15">Algunos de los beneficios de registrar tu empresa:</h5>
						<ul class="list_ok">
              <li>Comunica a tus clientes y proveedores que te interesa su opinión.</li>
							<li>Empieza a capturar testimonios de clientes de forma inmediata.</li>
							<li>Muestra en tu sitio web el rating personalizado de tu empresa de manera automática.</li>
              <li>Oportunidad de responder a testimonios de clientes.</li>							
						</ul>
					</div>					
				</div>
		
			</div>           
            </div>
      </div>
    </div>
        </main>   
      </Layout>
    );
};

export default SignUpReview
